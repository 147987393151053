@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
  .button-animation {
    @apply cursor-pointer scale-100 active:scale-975 sm:active:scale-975 transition ease-in-out duration-200 active:brightness-95 sm:active:brightness-95 sm:active:opacity-100 sm:hover:opacity-75 disabled:scale-100 disabled:hover:opacity-100 disabled:active:scale-100 disabled:cursor-not-allowed
  }
}

@font-face {
  font-family: "libre franklin";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/libre-franklin-v5-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Libre Franklin"), local("LibreFranklin-Regular"),
    url("/assets/fonts/libre-franklin-v5-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/assets/fonts/libre-franklin-v5-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("/assets/fonts/libre-franklin-v5-latin-regular.woff") format("woff"),
    /* Modern Browsers */
      url("/assets/fonts/libre-franklin-v5-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/assets/fonts/libre-franklin-v5-latin-regular.svg#LibreFranklin")
      format("svg");
  /* Legacy iOS */
}

/* libre-franklin-700 - latin */
@font-face {
  font-family: "libre franklin";
  font-style: normal;
  font-weight: 700;
  src: url("/assets/fonts/libre-franklin-v5-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Libre Franklin Bold"), local("LibreFranklin-Bold"),
    url("/assets/fonts/libre-franklin-v5-latin-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/assets/fonts/libre-franklin-v5-latin-700.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("/assets/fonts/libre-franklin-v5-latin-700.woff") format("woff"),
    /* Modern Browsers */ url("/assets/fonts/libre-franklin-v5-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/assets/fonts/libre-franklin-v5-latin-700.svg#LibreFranklin")
      format("svg");
  /* Legacy iOS */
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/MaterialIcons-Regular.eot");
  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url("/assets/fonts/MaterialIcons-Regular.woff2") format("woff2"),
    url("/assets/fonts/MaterialIcons-Regular.woff") format("woff"),
    url("/assets/fonts/MaterialIcons-Regular.ttf") format("truetype");
}
@import "./theme.scss";

/* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .backdrop-filter.backdrop-brightness-50 {
    background-color: rgba(0, 0, 0, 0.452);
  }
}

@media (max-width: 640px) {
  .waves {
    clip-path: polygon(100% 0%, 0% 0% , 0% 100.00%, 1% 99.97%, 2% 99.89%, 3% 99.76%, 4% 99.59%, 5% 99.36%, 6% 99.10%, 7% 98.80%, 8% 98.48%, 9% 98.13%, 10% 97.76%, 11% 97.39%, 12% 97.02%, 13% 96.66%, 14% 96.31%, 15% 95.99%, 16% 95.69%, 17% 95.43%, 18% 95.20%, 19% 95.03%, 20% 94.90%, 21% 94.82%, 22% 94.79%, 23% 94.82%, 24% 94.90%, 25% 95.03%, 26% 95.21%, 27% 95.43%, 28% 95.69%, 29% 95.99%, 30% 96.32%, 31% 96.67%, 32% 97.03%, 33% 97.40%, 34% 97.77%, 35% 98.13%, 36% 98.48%, 37% 98.81%, 38% 99.11%, 39% 99.37%, 40% 99.59%, 41% 99.77%, 42% 99.90%, 43% 99.97%, 44% 100.00%, 45% 99.97%, 46% 99.89%, 47% 99.76%, 48% 99.58%, 49% 99.36%, 50% 99.10%, 51% 98.80%, 52% 98.47%, 53% 98.12%, 54% 97.76%, 55% 97.39%, 56% 97.02%, 57% 96.65%, 58% 96.31%, 59% 95.98%, 60% 95.68%, 61% 95.42%, 62% 95.20%, 63% 95.02%, 64% 94.89%, 65% 94.82%, 66% 94.79%, 67% 94.82%, 68% 94.90%, 69% 95.03%, 70% 95.21%, 71% 95.43%, 72% 95.70%, 73% 96.00%, 74% 96.32%, 75% 96.67%, 76% 97.04%, 77% 97.41%, 78% 97.78%, 79% 98.14%, 80% 98.49%, 81% 98.81%, 82% 99.11%, 83% 99.37%, 84% 99.59%, 85% 99.77%, 86% 99.90%, 87% 99.98%, 88% 100.00%, 89% 99.97%, 90% 99.89%, 91% 99.76%, 92% 99.58%, 93% 99.35%, 94% 99.09%, 95% 98.79%, 96% 98.46%, 97% 98.12%, 98% 97.75%, 99% 97.38%, 100% 97.01%);
  }

  .parallelogram {
    clip-path: polygon(0 10%, 100% 0%, 100% 90%, 0% 100%);
  }

  .arrow-up {
    clip-path: polygon(0 10%, 50% 0, 100% 10%, 100% 100%, 50% 90%, 0 100%);
  }
}

/* Fonts */

/* libre-franklin-regular - latin */

.mat-icon {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;

  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;

  text-rendering: optimizeLegibility;

  -moz-osx-font-smoothing: grayscale;

  font-feature-settings: "liga";
}

.angular-editor-toolbar-set {
  border-radius: 6px;
}

.angular-editor-textarea, .angular-editor-toolbar {
  border-radius: 6px;
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  /* @apply rounded-sm shadow-sm border-gray-300; */
}

.mat-menu-panel, .mat-menu, .mat-menu-content {
  border-radius: 0.75rem!important;
  overflow: hidden;
}


/*
.mat-drawer-container {
  overflow: visible !important;
}

body ts-root * {
  font-family: "libre franklin", sans-serif;
  font-display: swap;
}

a {
  color: $ts-primary;
}

.mat-list-item a:hover,
a {
  text-decoration: none;
}

body {
  margin: 0;
}

.ts-margin,
.ts-margin.mat-mini-fab {
  margin: 10px;
}

.ts-padding {
  padding: 10px;
}

.ts-full-width {
  width: 100%;
}

.ts-no-padding-left {
  padding-left: 0 !important;
}

.ts-no-margin {
  margin: 0 !important;
}

a.ts-no-margin-left {
  margin-left: 0 !important;
}

.ts-no-padding {
  padding: 0 !important;
}

.ts-container {
  max-width: 1200px;
  margin: 0 auto;
}

.ts-truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mat-card {
  margin: 8px;
  border-radius: 20px !important;
}

.mat-card .mat-tab-link,
.mat-tab-label {
  min-width: 60px !important;
}

.ts-right {
  float: right;
}

.mat-card h1,
.mat-card h2,
.mat-card h3,
.mat-card h4,
.mat-card h5,
.mat-card h6 {
  text-align: left;

}

.mat-card h1,
.mat-card h2 {
  padding-top: 24px !important;
}

.ts-outer-heading {
  font-size: 30px;
  padding-right: 10px;
  margin-bottom: 0;
}

.center {
  text-align: center;
  margin: auto;
}

.input100 {
  width: calc(100% - 20px);
  margin: 10px;
}

.input50 {
  width: calc(50% - 20px);
  margin: 10px;
}

@media (max-width: 600px) {
  .input50 {
    width: calc(100% - 20px);
    margin: 10px;
  }
}

strong {
  font-weight: 700 !important;
}


a .mat-button-wrapper {
  color: white;
}

.mat-list-item-content .mat-icon {
  margin-right: 10px;
}



.ts-grid,
.ts-grid-video {
  flex-wrap: wrap;
}

.ts-grid-video mat-card {
  flex-basis: 100% !important;
}

.ts-grid mat-card .ts-grid-text {
  margin: 24px;
}

.ts-card-content {
  padding: 24px;
}

.ts-card-content h2:first-child {
  margin-top: 0;
}

.ts-grow {
  flex-grow: 1;
}



@media screen and (max-width: 399px) {
  .ts-grid mat-card.mat-card {
    max-width: calc(100% - 16px);
    padding: 0;
  }
}



@media screen and (min-width: 400px) and (max-width: 599px) {
  .ts-grid mat-card.mat-card {
    max-width: calc(50% - 16px);
    padding: 0;
  }
}



@media screen and (min-width: 600px) and (max-width: 959px) {
  .ts-grid mat-card.mat-card {
    max-width: calc(33% - 16px);
    padding: 0;
  }
}



@media screen and (min-width: 960px) {
  .ts-grid mat-card.mat-card {
    max-width: calc(25% - 16px);
    padding: 0;
  }
}


@media screen and (max-width: 750px) {
  .ts-grid-video mat-card.mat-card {
    max-width: calc(100% - 16px);
    padding: 0;
  }
}

@media screen and (min-width: 750px) and (max-width: 1125px) {
  .ts-grid-video mat-card.mat-card {
    max-width: calc(50% - 16px);
    padding: 0;
  }
}

@media screen and (min-width: 1125px) {
  .ts-grid-video mat-card.mat-card {
    max-width: calc(33% - 16px);
    padding: 0;
  }
}

.mat-card.textCard {
  position: absolute;
  opacity: 0.7;
  bottom: 30px;
  left: 30px;
  min-width: 250px;
  max-width: 300px;
}

.mat-card.textCard button.mat-button {
  margin-left: 0 !important;
}

.mat-card.textCard.left {
  text-align: left;
}

.mat-card.imgCard img,
.mat-card.imgCard picture {
  margin-bottom: -5px;
}

.mat-card.imgCard {
  padding: 0;
  overflow: hidden;
}

.fixed-ratio {
  display: block;
  position: relative;
  height: 0;
  overflow: hidden;
}

.fixed-ratio img.fixed-ratio-content,
.fixed-ratio picture.fixed-ratio-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100%;
  max-width: inherit;
  margin: 0;
}

.subLink:hover {
  text-decoration: underline;
}



* {
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  position: relative;
}

main {
  min-height: calc(100vh - 128px);

  overflow: hidden;
  display: block;
  position: relative;

}

.mat-card:not(.imgCard) {
  padding-bottom: 16px !important;
}


.mat-tab-link,
.mat-menu-panel,
.mat-select-panel,
.mat-expansion-panel,
.mat-step-header {
  border-radius: 20px !important;
}
*/
