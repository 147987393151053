@use '@angular/material' as mat;
//@import "~mapbox-gl/dist/mapbox-gl.css";
//@import "~@mapbox/mapbox-gl-geocoder/lib/mapbox-gl-geocoder.css";
$ts-orange: (
  50: #fad1bb,
  100: #f4a072,
  200: #f2884e,
  300: #ed6317,
  400: #ed6317,
  500: #e05a11,
  600: #ce5310,
  700: #bc4b0e,
  800: #a9440d,
  900: #973d0b,
  A100: #f4a072,
  A200: #f2884e,
  A400: #ed6317,
  A700: #bc4b0e,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white,
  ),
);
$ts-blue: (
  50: #9bbee2,
  100: #6da0d5,
  200: #4f8ccc,
  300: #4082c7,
  400: #326fad,
  500: #2e659e,
  600: #2a5b8f,
  700: #255280,
  800: #214870,
  900: #1c3e61,
  A100: #6da0d5,
  A200: #4f8ccc,
  A400: #326fad,
  A700: #255280,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white,
  ),
);
/* Colors */

$ts-primary: #e05a11;
$ts-primary-light: #ff8b43;
$ts-primary-dark: #a72800;
$ts-secondary: #2e659e;
$ts-secondary-light: #6492cf;
$ts-secondary-dark: #003b6f;
$ts-text-on-primary: white;
$ts-text-on-secondary: white;
.bg-primary {
  background-color: $ts-primary;
  color: white;
}

.mat-checkbox-layout {
  white-space: normal !important;
}

@media (max-width: 400px) {
  .mat-vertical-stepper-header.mat-step-header {
    padding-left: 0;
  }
  .mat-vertical-content-container {
    margin-left: 12px !important;
  }
  .mat-vertical-content {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
// @include mat.all-legacy-component-typographies();
@include mat.core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ts-app-primary: mat.m2-define-palette($ts-orange);
$ts-app-accent: mat.m2-define-palette($ts-blue, A200, A100, A400);
// The warn palette is optional (defaults to red).
$ts-app-warn: mat.m2-define-palette(mat.$m2-red-palette);
// Create the theme object (a Sass map containing all of the palettes).
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.


$ts-app-theme: mat.m2-define-light-theme((
 color: (
   primary: $ts-app-primary,
   accent: $ts-app-accent,
   warn: $ts-app-warn,
 ),
 typography: mat.m2-define-typography-config(),
 density: 0,
));

@include mat.all-component-themes($ts-app-theme);